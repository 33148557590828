import "./testimonial.scss";
import { useNavigate } from "react-router-dom";
function Testimonial({ name, picture, content, label, link }) {
  const navigate = useNavigate();
  return (
    <div className="testimonialCard">
      <img className="testimonialImage" alt="testimonial" src={picture}></img>

      <div className="testimonialInfo">
        <div className=" testimonialInfo testiomnialHeader">
          <span className=" testimonialTitle">{name}</span>
          {link == "" ? (
            <span className="testimonialLabel">{label}</span>
          ) : (
            <a className="testimonialanchor" href={link}>
              {" "}
              Read Blog{" "}
            </a>
          )}
        </div>
        <span className="testimonialContent">{content}</span>
      </div>
    </div>
  );
}
export default Testimonial;
