import logo from "./logo.svg";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useWindowWidth } from "@react-hook/window-size";
import Main from "./pages/main";
import WebDevelopmentInLebanon from "./pages/web-development";
import NotFound from "./pages/NotFound";
import CreateAWebsiteInLebanon from "./pages/how-to-create-your-own-website";
import MobileAppDevelopment from "./pages/mobile-app-development-in-lebanon";
function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Main />}></Route>
        <Route
          exact
          path="/web-development-in-lebanon"
          element={<WebDevelopmentInLebanon />}
        ></Route>
        <Route
          exact
          path="/how-to-create-your-own-website"
          element={<CreateAWebsiteInLebanon />}
        ></Route>
        <Route
          exact
          path="/mobile-app-development-in-lebanon"
          element={<MobileAppDevelopment />}
        ></Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
export default App;
