export const ProjectsData = [
  {
    name: "Cryptorabic",
    picture: "/projects/cryptorabic.png",
    url: "https://cryptorabic.com",
    content:
      "Crypto Community Website and Web3 marketplace, connect your wallet and buy crypto courses",
  },
  {
    name: "Social Media Lebanon",
    picture: "/projects/smlebanon.png",
    url: "https://smlebanon.com",
    content:
      "Social Media Auction with a store for buying social media goods, create an account and start shopping",
  },
  {
    name: "Devrabic",
    picture: "/projects/devrabic.png",
    url: "https://devrabic.com",
    content:
      "Devrabic is a full web development course purchasable by everyone who would like to learn",
  },
  {
    name: "S.M.S Security NFT",
    picture: "/projects/smssecurity.png",
    url: "https://opensea.io/collection/s-m-s-security",
    content:
      "Implementation of the smart contract of the NFT collection, with the designs also provided by our team",
  },

  {
    name: "Killjoy 7",
    picture: "/projects/killjoy7.png",
    url: "https://killjoy7.com/",
    content:
      "Killjoy 7 is a web application that allows users to view teams and their league standings",
  },
  {
    name: "Cryptorabic App",
    picture: "/projects/cryptorabicapp.png",
    url: "https://play.google.com/store/apps/details?id=cryptorabic.com",
    content:
      "Mobile app, real time chatroom and crypto news application controlled by admins and traders",
  },
];

export const TestimonialData = [
  {
    name: "Elie Yazbeck",
    picture: "/testimonials/elieyazbeck.png",
    content:
      "Explonis developed my website and thanks to them, i know rank on top of the google pages for several keywords like “barbers in Lebanon”",
    label: "Owner of Salon E",
    link: "",
  },
  {
    name: "Gabriel Achkar",
    picture: "/testimonials/gabyachkar.png",
    content:
      "I wanted to create an NFT collection, so i reached out to Explonis and they did the whole work from A to Z. Now i can send NFTs to whoever i want",
    label: "Owner of SMS Security",
    link: "",
  },
  {
    name: "Hanna Mittri",
    picture: "/testimonials/hannamittri.png",
    content:
      "Explonis is amazing, they deliver the work at time, create clean and creative applications, and the communciation they have with the client is really on point",
    label: "Community Manager at Killjoy",
    link: "",
  },
];
export const BlogsData = [
  {
    name: "Web Dev in Lebanon",
    picture: "/blogs/webdev1.jpg",
    content:
      "Are you looking for Web developers in Lebanon? If yes, then this blog will be your perfect landing destination. Explore it now!",
    label: "",
    link: "/web-development-in-lebanon",
  },
  {
    name: "How to create a website?",
    picture: "/blogs/webdev4.jpg",
    content:
      "Are you looking to learn how to create your own website in Lebanon? this article will show you how!",
    label: "",
    link: "/how-to-create-your-own-website",
  },
  {
    name: "Mobile App Dev in Lebanon",
    picture: "/blogs/mobiledev1.jpg",
    content:
      "Are you looking for a mobile app developer in Lebanon? check out this blog that shows you everything you need to know about mobile apps",
    label: "",
    link: "/mobile-app-development-in-lebanon",
  },
];
