import { useWindowWidth } from "@react-hook/window-size";
import { useLayoutEffect, useState, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { FallingLines, ThreeDots, Hearts } from "react-loader-spinner";
import { Fade } from "react-reveal";
import { AiOutlineSend } from "react-icons/ai";
import { FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";

import emailjs from "@emailjs/browser";
import Testimonial from "../components/testimonial/testimonial";
import { ParcelsC } from "../components/parcels";
import Project from "../components/project/project";
import { ProjectsData, TestimonialData, BlogsData } from "../utils/content";
function Main() {
  const onlyWidth = useWindowWidth();
  const [formInputs, setFormInputs] = useState({
    from_name: "",
    message: "",
  });
  const [formError, setformError] = useState("");
  const [formSuccess, setformSuccess] = useState(false);
  const [formLoading, setformLoading] = useState(false);
  const form = useRef();
  const currentYear = new Date().getFullYear();

  const handleChange = (e) => {
    setFormInputs((previousState) => ({
      ...previousState,
      [e.target.name]: e.target.value,
    }));
  };
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formInputs.from_name === "" || formInputs.message === "") {
      setformError("Fields are empty!");
    } else {
      if (!regex.test(formInputs.from_name)) {
        setformError("Wrong Email Format!");
      } else {
        setformLoading(true);
        emailjs
          .sendForm(
            "service_vugueu8",
            "template_g21zkh8",
            form.current,
            "la1kuC0n8kksD5IuJ"
          )
          .then(
            (result) => {
              console.log(result.text);
              setformLoading(false);
              setformError("");
              setformSuccess(true);
              setFormInputs({
                from_name: "",
                message: "",
              });
            },
            (error) => {
              console.log(error.text);
              setformError("Internal Error!");
            }
          );
      }
    }
  };
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      let tl = gsap.timeline({ repeat: -1 });
      gsap.set(".hero_main_image", {
        // rotation: "-=10",
        rotateZ: "20",
        rotateX: "40",
        rotateY: "25",
      });
      tl.to(".hero_main_image", {
        duration: 3,

        rotateZ: "0",
        rotateX: "0",
        rotateY: "0",

        ease: "power1.easeInOut",
      }).to(".hero_main_image", {
        duration: 3,

        rotateZ: "20",
        rotateX: "40",
        rotateY: "25",
        ease: "power1.easeInOut",
      });
    });
    return () => ctx.revert();
  }, []);
  return (
    <div>
      <div className="parcels_mobile">
        <ParcelsC opacity={0.3} Hover={false} />
        <section>
          <div className="hero_container">
            <span className="hero_explonis_text">Explonis</span>

            <div className="hero_texts">
              {" "}
              <h1>freelance</h1>
              <h1 className="hero_webfalling">
                web{" "}
                <FallingLines
                  color="#ffffff"
                  width={onlyWidth > 1200 ? "100" : "70"}
                  visible={true}
                  ariaLabel="falling-lines-loading"
                />{" "}
              </h1>
              <h1>development</h1> <h1>studio with a </h1>
              <h1>passion</h1> <h1> for tech</h1>
            </div>

            <img
              alt="codebloc"
              src="codeblock.png"
              className="hero_main_image"
            />
          </div>
          <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 500 57.01"
          >
            <defs>
              <style>{`.cls-1{fill:#161515;}`}</style>
            </defs>
            <title>wavy-loops-top</title>
            <path
              class="cls-1"
              d="M0,0H500V27a281.22,281.22,0,0,1-89,14c-34.3-.27-52.92-7-75-11C250.31,14.33,217.45,57.92,131,57c-30.2-.32-75.43-6.17-131-35"
            />
          </svg>
        </section>

        <section style={{ marginTop: "200px" }}>
          <div className="projects_container">
            <Fade>
              <h2>Projects</h2>
            </Fade>
            <div className="projects_subcontainer">
              {ProjectsData.map((project, i) => {
                return (
                  <Fade key={i}>
                    <Project
                      name={project.name}
                      picture={project.picture}
                      url={project.url}
                      content={project.content}
                    />
                  </Fade>
                );
              })}
            </div>
          </div>
        </section>

        <section>
          <div className="projects_container">
            <Fade>
              {" "}
              <h2>Testimonials</h2>
            </Fade>
            <div className="projects_subcontainer">
              {TestimonialData.map((testimonial, i) => {
                return (
                  <Fade key={i}>
                    <Testimonial
                      name={testimonial.name}
                      picture={testimonial.picture}
                      label={testimonial.label}
                      content={testimonial.content}
                      link={testimonial.link}
                    />
                  </Fade>
                );
              })}
            </div>
          </div>
        </section>
        <section style={{ marginBottom: "200px" }}>
          <div className="projects_container">
            <Fade>
              {" "}
              <h2>Blogs</h2>
            </Fade>
            <div className="projects_subcontainer">
              {BlogsData.map((testimonial, i) => {
                return (
                  <Fade key={i}>
                    <Testimonial
                      name={testimonial.name}
                      picture={testimonial.picture}
                      label={testimonial.label}
                      content={testimonial.content}
                      link={testimonial.link}
                    />
                  </Fade>
                );
              })}
            </div>
          </div>
        </section>
        <section>
          <svg
            className="footer_svg"
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 500 57.01"
          >
            <defs>
              <style>{`.cls-1{fill:#161515;}`}</style>
            </defs>
            <title>wavy-loops-bottom</title>
            <path
              class="cls-1"
              d="M500,200H0V173a281.22,281.22,0,0,1,89-14c34.3.27,52.92,7,75,11,85.69,15.67,118.55-27.92,205-27,30.2.32,75.43,6.17,131,35"
              transform="translate(0 -142.99)"
            />
          </svg>

          <div className="footer_container">
            <Fade>
              <h2>Contact us</h2>
            </Fade>
            <Fade>
              <div className="footer_form">
                <div className="footer_heartsandformtext">
                  <Hearts
                    height="40"
                    width="80"
                    color="#000000"
                    ariaLabel="hearts-loading"
                    wrapperStyle={{}}
                    wrapperClass="footer_hearts"
                    visible={true}
                  />
                  <span className="footer_formText">Let us get in touch</span>
                </div>
                <form ref={form}>
                  <div className="footer_forminputs">
                    <input
                      value={formInputs.from_name}
                      onChange={handleChange}
                      type="email"
                      name="from_name"
                      placeholder="Email Address"
                      disabled={formSuccess}
                    ></input>
                    <textarea
                      name="message"
                      value={formInputs.message}
                      onChange={handleChange}
                      rows="5"
                      placeholder="Message"
                      disabled={formSuccess}
                    ></textarea>
                  </div>
                </form>
                {formError !== "" && (
                  <div className="footer_error">{formError}</div>
                )}
                {formSuccess && (
                  <div className="footer_sucess">Message sent, thank you!</div>
                )}
                {formLoading ? (
                  <div className="footer_buttonicon footer_buttonicon_loading">
                    <ThreeDots
                      height="25"
                      width="25"
                      radius="9"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : formSuccess ? (
                  <div className="footer_buttonicon footer_buttonicon_disabled">
                    <AiOutlineSend />
                  </div>
                ) : (
                  <div
                    className="footer_buttonicon footer_buttonicon_hover"
                    onClick={handleSubmit}
                  >
                    <AiOutlineSend />
                  </div>
                )}
              </div>
            </Fade>
            <Fade>
              <div className="footer_mikemajdalanai">
                {" "}
                © {currentYear} - Developed by{" "}
                <a href="https://mikemajdalani.com"> Mike Majdalani</a>
              </div>
            </Fade>
          </div>
        </section>
      </div>
    </div>
  );
}
export default Main;
