import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

export const ParcelsC = ({ Hover, opacity }) => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);
  const defaultpics = [
    "./parcels/css.png",
    "./parcels/html.png",
    "./parcels/js.png",
    "./parcels/solidity.png",
    "./parcels/next.png",
    "./parcels/react.png",
    "./parcels/firebase.png",
    "./parcels/ionic.png",
    "./parcels/node.png",
  ];

  const particlesOptions = {
    background: {
      color: {
        value: "transparent",
      },
    },
    fpsLimit: 60,
    interactivity: {
      events: {
        onClick: {
          enable: false,
          mode: "push",
        },
        onHover: {
          enable: Hover,
          mode: "bubble",
        },
        resize: true,
      },
      modes: {
        bubble: {
          distance: 400,
          duration: 2,
          opacity: 0.8,
          size: 30,
        },
        push: {
          quantity: 4,
        },
        repulse: {
          distance: 100,
          duration: 0.2,
          speed: 1,
        },
      },
    },
    fullScreen: {
      enable: true,
      zIndex: 0,
    },
    particles: {
      color: {
        value: "#ffffff",
      },
      links: {
        color: "#ffffff",
        distance: 350,
        enable: false,
        opacity: 0.5,
        width: 1,
      },
      collisions: {
        enable: false,
      },
      move: {
        direction: "top",
        enable: true,
        outMode: "out",
        random: false,
        speed: 2.5,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 2000,
        },
        value: 70,
      },
      opacity: {
        value: opacity,
      },
      shape: {
        options: {
          images: [
            {
              src: defaultpics[0],
              width: 32,
              height: 32,
            },
            {
              src: defaultpics[1],
              width: 32,
              height: 32,
            },
            {
              src: defaultpics[2],
              width: 32,
              height: 32,
            },
            {
              src: defaultpics[3],
              width: 32,
              height: 32,
            },
            {
              src: defaultpics[4],
              width: 32,
              height: 32,
            },
            {
              src: defaultpics[5],
              width: 32,
              height: 32,
            },
            {
              src: defaultpics[6],
              width: 32,
              height: 32,
            },
            {
              src: defaultpics[7],
              width: 32,
              height: 32,
            },
            {
              src: defaultpics[8],
              width: 32,
              height: 32,
            },
          ],
        },
        type: "image",
      },
      size: {
        random: true,
        value: 20,
      },
    },
    detectRetina: true,
  };

  return (
    <div className="parcels">
      <Particles init={particlesInit} options={particlesOptions} />
    </div>
  );
};
